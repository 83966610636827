import React from 'react'

interface Props {
    label?: string
    name: string
    type: string
    value: any
    index?: string | number
    placeholder?: string
    required?: boolean
    onChange?: (any) => void
    classes?: string
    error?: string | undefined | null
    onKeyUp?: (any) => void
    onBlur?: (any) => void
    maxLength?: number
    readonly?: boolean
    rows?: number
    disabled?: boolean
    min?: number
    max?: number
    step?: number
}

const DefaultInput = ({
    label,
    name,
    type,
    onChange,
    index = '',
    placeholder = '',
    required = false,
    value = '',
    classes = '',
    error,
    onKeyUp,
    onBlur,
    maxLength,
    readonly = false,
    rows = 5,
    disabled = false,
    min = 0,
    max = 100,
    step = 1,
}: Props) => {
    return (
        <div className={`flex flex-col w-full`}>
            {label !== '' && (
                <label className="capitalize mb-1 text-sm" htmlFor={name}>
                    {label}
                </label>
            )}

            {type !== 'textarea' ? (
                <input
                    className={`p-1 border-b-2 text-gray-900 font-primary placeholder-gray-700 bg-transparent  border-gray-500 text-sm max-w-90% ${classes} ${
                        readonly && 'bg-gray-300'
                    } focus:border-primaryOrange focus:border-b-2 focus:outline-none ${
                        error ? 'border-red-500' : 'border-gray-300'
                    }`}
                    data-key={index}
                    type={type}
                    name={name}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    onBlur={onBlur}
                    placeholder={placeholder !== '' ? placeholder : label}
                    required={required}
                    value={value}
                    readOnly={readonly}
                    disabled={disabled}
                    min={min}
                    max={max}
                    step={step}
                />
            ) : (
                <textarea
                    className={`p-1 bg-transparent border-2 focus:outline-none  border-gray-500 focus:border-primary max-w-90% text-sm ${classes}`}
                    data-key={index}
                    name={name}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    onBlur={onBlur}
                    placeholder={placeholder !== '' ? placeholder : label}
                    required={required}
                    value={value}
                    maxLength={maxLength}
                    readOnly={readonly}
                    rows={rows}
                ></textarea>
            )}

            <p className="text-xs text-red-500 mt-2">{error !== undefined && error}</p>
        </div>
    )
}

export default DefaultInput
