import Analytics from 'analytics'
import IAnalytics from '../../types/Analytics/Analytics'
import googleAnalytics from '@analytics/google-analytics'
import googleTagManager from '@analytics/google-tag-manager'

export const loadAnalytics = (analytics: IAnalytics): any => {
    const plugins = Object.keys(analytics)

    let instances = []

    plugins.map((plugin) => {
        switch (plugin) {
            case 'googleAnalytics':
                if (analytics[plugin].trackingId && analytics[plugin].trackingId !== '') {
                    const GA = googleAnalytics({
                        trackingId: analytics[plugin].trackingId,
                    })
                    instances.push(GA)
                }
                break
            case 'tagManagerSettings':
                if (analytics[plugin].containerId !== 'GTM-') {
                    const GTM = googleTagManager({
                        containerId: analytics[plugin].containerId,
                        dataLayerName: analytics[plugin].dataLayerName,
                    })
                    instances.push(GTM)
                }
                break
            default:
                break
        }
    })

    const deployableGTM = Object.assign(
        {},
        googleTagManager({
            containerId: 'GTM-NW73Z8W',
            dataLayerName: 'landingPage',
        }),
        { name: 'deployable-GTM' }
    )

    instances.push(deployableGTM)

    const gtm = Analytics({
        app: 'deploy-link',
        plugins: instances,
    })

    return gtm
}
