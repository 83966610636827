import React from 'react'
import Head from 'next/head'

interface Props {
    siteTitle: string
    description: string
    twitter?: string
    image?: string
    font?: {
        family: string
        variants?: [string]
        url?: string
        extension?: string
    } // Type font
    hostUrl: string
    fonts?: any
}

const SEOComponent = ({ siteTitle, description, twitter, image, font, fonts, hostUrl }: Props) => {
    let fontFamily = 'Poppins'
    let modFontFamily = 'Poppins'
    let fontString = ''

    if (font && font.family) {
        fontFamily = font.family
        modFontFamily = !font?.url ? fontFamily?.replace(/\s/g, '+') : fontFamily
    }

    if (font && !font.url) {
        fontString = `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);\n `
    } else if (font && font.url) {
        fontString = `@font-face {
        font-family: "${font.family}";
        src: url("${font.url}") format("${font.extension}");
    };\n `
    }

    return (
        <Head>
            <title>{siteTitle}</title>
            <link rel="icon" href="/favicon.ico" />
            <meta name="og:title" content={siteTitle} />
            <meta httpEquiv="Content-Security-Policy" content="upgrade-insecure-requests" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta charSet="UTF-8" />
            <meta name="description" content={description} />

            <meta property="og:type" content="website" />
            <meta property="og:url" content={hostUrl} />
            <meta property="og:title" content={siteTitle} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={hostUrl} />
            <meta property="twitter:title" content={siteTitle} />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image" content={image} />

            <link
                href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="preload"
                as="font"
                crossOrigin="anonymous"
            ></link>

            {/* <script async src="https://unpkg.com/analytics/dist/analytics.min.js"></script> */}

            <style>{fontString}</style>

            {fonts &&
                fonts.length > 0 &&
                fonts.map((font, index) => {
                    if (font.family) {
                        if (font.url === '' || font.url === undefined) {
                            const modFontFamily = font.family.replace(/\s/g, '+')
                            return (
                                <style key={index}>
                                    {`@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`}
                                </style>
                            )
                        } else {
                            return (
                                <style key={index}>
                                    {`@font-face {
                                        font-family: '${font.family}';
                                        src: url('${font.url}') format('${font.extension}');
                                    }`}
                                </style>
                            )
                        }
                    }
                })}
        </Head>
    )
}

export default SEOComponent
