import {atomFamily} from 'recoil'

export const analyticsState = atomFamily<any, string>({
    key: 'analyticsState',
    default: {
        load: ()=>{},
        track: ()=> {},
        page: ()=>{},
        identify: ()=>{}
    }
})