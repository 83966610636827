import React from 'react'

interface Props {
    height: string;
    width: string;
}

const SuccessIcon = ({height, width}: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 455.431 455.431"><path d="M405.493 412.764c-69.689 56.889-287.289 56.889-355.556 0-69.689-56.889-62.578-300.089 0-364.089s292.978-64 355.556 0 69.689 307.201 0 364.089z" fill="#8dc640"/><path d="M229.138 313.209c-62.578 49.778-132.267 75.378-197.689 76.8-48.356-82.489-38.4-283.022 18.489-341.333 51.2-52.622 211.911-62.578 304.356-29.867 22.755 93.867-24.178 213.333-125.156 294.4z" fill="#fff" opacity=".2"/><path d="M195.004 354.453c-9.956 0-19.911-4.267-25.6-12.8l-79.644-102.4c-11.378-14.222-8.533-34.133 5.689-45.511s34.133-8.533 45.511 5.689l54.044 69.689 119.467-155.022c11.378-14.222 31.289-17.067 45.511-5.689s17.067 31.289 5.689 45.511L220.604 341.653c-7.111 7.111-15.644 12.8-25.6 12.8z" fill="#fff"/></svg>
    )
}



export default SuccessIcon
