import React, { useState } from 'react'
import Vimeo from '@u-wave/react-vimeo'
import SocialIcons from './Icons/SocialIcons'
import Modal from '../../Modal'
import InputField from '../../Inputs/DefaultInput'
import ButtonSpinner from '../../ButtonSpinner'
import SuccessIcon from '../../SuccessIcon'

interface Props {
    data: {
        uid: string
        campaignId: string
        campaignName: string
        campaignDescription: string
        title: string
        heading1: string
        heading2: string
        subheading: string
        backgroundColour: string
        backgroundImageUrl: string
        logoImageUrl: string
        textColour: string
        buttonColour: string
        iconColour: string
        buttonTextColour: string
        socialLinks: {
            facebook: string
            instagram: string
            twitter: string
            linkedin: string
        }
        content: {
            type: string
            url: string
            description: string
        }
        buttonLinks: object
        covidSignIn: boolean
        font: {
            category: string
            family: string
            files: {
                italic?: string
                regular: string
            }
            id: string
            kind: string
            lastModified: string
            script: [string]
            variants: [string]
            version: string
        }
        headerText: string
    }
}

const ConfirmationModal = ({ visible, toggle }) => {
    return (
        <Modal modalOpen={visible} toggleModal={toggle} classes="w-90% p-4 " closeEnabled={false}>
            <div className="mt-8 w-full flex flex-col justify-center items-center">
                <SuccessIcon height="80px" width="80px" />
                <p className="text-base my-4 text-grayish">Form successfully submitted!</p>
                <button
                    className="w-full p-2 text-base rounded-full font-medium bg-gray-900 text-white mt-6"
                    onClick={() => toggle(false)}
                >
                    Close
                </button>
            </div>
        </Modal>
    )
}

const CovidForm = ({
    covidFormVisible,
    toggleCovidForm,
    id,
    toggleConfirmation,
    uid,
}: {
    covidFormVisible: boolean
    toggleCovidForm: (value: boolean) => void
    id: string
    toggleConfirmation: (value: boolean) => void
    uid: string
}) => {
    const [formValues, setFormValues] = useState<{
        firstName: string
        lastName: string
        email: string
        phone: string
    }>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    })

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')

    async function handleFormSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault()
        setLoading(true)
        setError('')
        try {
            const res = await fetch('/api/campaignFormSubmit', {
                method: 'POST',
                body: JSON.stringify({
                    ...formValues,
                    createdAt: new Date().toISOString(),
                    id: id,
                    uid: uid,
                    name: 'Covid Sign In',
                }),
            })

            if (res.ok) {
                toggleCovidForm(false)
                setFormValues({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                })
                toggleConfirmation(true)
                // show confirmation page
            }
        } catch (error) {
            //console.log(error)
            setError(error)
        }

        setLoading(false)
    }

    function handleTextChange(e: React.ChangeEvent<HTMLInputElement>): void {
        setFormValues((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            }
        })
    }

    return (
        <Modal modalOpen={covidFormVisible} toggleModal={toggleCovidForm} classes="w-90% p-6">
            <h1 className="text-lg font-bold mb-2 text-grayish">Contact Tracing</h1>
            <p className="my-2 text-sm  text-grayish">
                Please ensure your intended stay with us does not exceed 90 minutes.
            </p>
            <form onSubmit={handleFormSubmit}>
                <InputField
                    label="First Name"
                    name="firstName"
                    onChange={handleTextChange}
                    type="text"
                    value={formValues['firstName']}
                    required={true}
                />
                <InputField
                    label="Last Name"
                    name="lastName"
                    onChange={handleTextChange}
                    type="text"
                    value={formValues['lastName']}
                    required={true}
                />
                <InputField
                    label="Email"
                    name="email"
                    onChange={handleTextChange}
                    type="email"
                    value={formValues['email']}
                    required={true}
                />
                <InputField
                    label="Phone"
                    name="phone"
                    onChange={handleTextChange}
                    type="text"
                    value={formValues['phone']}
                    required={true}
                />

                {error !== '' && <p className="mt-2 mb-4 text-red-500 text-sm">{error}</p>}
                <button
                    disabled={loading}
                    className="w-full p-2 rounded-full bg-gray-900 text-white text-sm font-medium flex justify-center"
                    type="submit"
                >
                    {loading ? <ButtonSpinner height="25px" width="25px" /> : 'Submit Information'}
                </button>
            </form>
        </Modal>
    )
}

const LiveTemplate = ({ data }: Props) => {
    const [covidFormVisible, toggleCovidForm] = useState<boolean>(false)
    const [confirmationVisible, toggleConfirmation] = useState<boolean>(false)

    let headerText = data.headerText
    // some issue with react quill class names not doing correct classes
    if (headerText !== undefined) {
        headerText = headerText.replace(/ql-align-center/g, 'text-center')
        headerText = headerText.replace(/ql-align-right/g, 'text-right')
    }

    let fontFamily = data.font && data.font.family ? data.font.family : 'Open Sans'
    fontFamily = fontFamily.split(' ').join('+')

    return (
        <>
            {data.headerText && data.headerText.length > 1 && (
                <style jsx global>
                    {`
                        h1 {
                            font-size: 2.5em;
                        }

                        h2 {
                            font-size: 2.25em;
                        }

                        h3 {
                            font-size: 2em;
                        }

                        h4 {
                            font-size: 1.75em;
                        }

                        h5 {
                            font-size: 1.5em;
                        }

                        h6 {
                            font-size: 1.25em;
                        }

                        p {
                            font-size: 1em;
                        }

                        .header-text img {
                            margin: 0 auto;
                        }
                    `}
                </style>
            )}

            <div
                style={{
                    backgroundImage: `url("${data.backgroundImageUrl || ''}")`,
                    backgroundColor: data.backgroundColour || '#FFFFFF',
                    color: `${data.textColour}`,
                    zIndex: -1,
                }}
                className="fixed inset-0  bg-center bg-cover bg-no-repeat text-xs break-words h-screen w-screen "
            ></div>

            <div
                className="max-w-lg flex flex-col items-center mx-auto justify-between h-full z-20 pt-6 px-6 pb-10 overflow-y-auto"
                style={{
                    fontFamily: data.font && data.font.family ? data.font.family : 'Open Sans',
                    color: data.textColour,
                }}
            >
                <img
                    src={data.logoImageUrl}
                    alt="logo"
                    className="max-h-10vh max-w-1/2"
                    style={{ marginBottom: '1em' }}
                />

                {data.headerText && data.headerText.length > 1 ? (
                    <div
                        className="header-text w-full"
                        style={{ color: `${data.textColour} !important`, margin: '1.5em 0' }}
                        dangerouslySetInnerHTML={{ __html: headerText }}
                    />
                ) : (
                    <div style={{ color: data.textColour }}>
                        <h1 className="text-xl text-center">{data.title}</h1>
                        <h2 className="text-lg text-center">{data.heading1}</h2>
                        <h2 className="text-lg text-center">{data.heading2}</h2>
                        <h3 className="text-base text-center mb-8">{data.subheading}</h3>
                    </div>
                )}

                <div className="flex items-center justify-center w-3/4">
                    {data.socialLinks &&
                        Object.entries(data.socialLinks).map((links) => {
                            if (links[1] !== '') {
                                return (
                                    <div
                                        key={`social-links-${links[0]}`}
                                        style={{ marginTop: '1.5em', marginBottom: '1.5em' }}
                                    >
                                        <SocialIcons
                                            platform={links[0]}
                                            height="2.5em"
                                            width="2.5em"
                                            color={data.iconColour}
                                            link={links[1]}
                                            classes="mx-3"
                                        />
                                    </div>
                                )
                            }
                        })}
                </div>

                <div className="w-full">
                    {data.content?.type === 'image' && (
                        <div className="w-full flex flex-col items-center justify-center">
                            <img
                                src={data.content.url}
                                alt="content-image"
                                style={{ margin: '0.4em 0' }}
                            />
                        </div>
                    )}

                    {data.content?.type === 'video' && data.content.url.includes('youtube') && (
                        <iframe
                            width="100%"
                            className="min-h-30vh mb-4"
                            height="auto"
                            style={{ margin: '1em 0' }}
                            src={data.content.url}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    )}

                    {data.content?.type === 'video' && data.content.url.includes('vimeo') && (
                        <div className="w-full m-auto  mb-4" style={{ margin: '1em 0' }}>
                            <Vimeo
                                video={data.content.url}
                                width="100%"
                                height="auto"
                                responsive={true}
                                controls={true}
                            />
                        </div>
                    )}

                    {data.content?.type === 'video' &&
                        !data.content.url.includes('youtube') &&
                        !data.content.url.includes('vimeo') && (
                            <video
                                className="w-full m-auto mb-4"
                                style={{ margin: '1em 0' }}
                                src={data.content.url}
                                controls
                                playsInline
                            ></video>
                        )}
                </div>

                <p
                    className="text-base my-4 w-full text-center break-words"
                    style={{ color: data.textColour }}
                >
                    {data.content?.description}
                </p>

                <div className="w-full">
                    {data.covidSignIn === true && (
                        <button
                            type="button"
                            className="max-w-90% w-full bg-gray-200 rounded-full text-center  mb-1 mt-2 font-bold focus:outline-none"
                            style={{
                                backgroundColor: data.buttonColour,
                                color: data.buttonTextColour,
                                padding: '0.65em',
                                fontSize: '1em',
                            }}
                            onClick={() => toggleCovidForm(true)}
                        >
                            Covid Sign In
                        </button>
                    )}

                    <CovidForm
                        covidFormVisible={covidFormVisible}
                        toggleCovidForm={toggleCovidForm}
                        id={data.campaignId}
                        toggleConfirmation={toggleConfirmation}
                        uid={data.uid}
                    />

                    <ConfirmationModal visible={confirmationVisible} toggle={toggleConfirmation} />

                    {data.buttonLinks &&
                        Object.values(data.buttonLinks).map((links, index) => {
                            if (links.linkTitle && links.linkTitle.length > 0) {
                                return (
                                    <div key={index}>
                                        <button
                                            className="max-w-90% w-full rounded-full text-center font-medium my-1 font-bold focus:outline-none"
                                            style={{
                                                backgroundColor: data.buttonColour,
                                                color: data.buttonTextColour,
                                                padding: '0.65em',
                                                fontSize: '1em',
                                            }}
                                        >
                                            <a className="inline-block w-full" href={links.linkUrl}>
                                                {links.linkTitle}
                                            </a>
                                        </button>
                                    </div>
                                )
                            }
                        })}
                </div>

                <div className="flex items-center justify-center my-8 rounded-full bg-black p-2 w-25% opacity-75 mb-16">
                    <a href="https://deployable.co">
                        <img
                            src="/images/dashboard/Deployable_Logo_Orange_White.png"
                            alt="deployable"
                            className="w-full"
                        />
                    </a>
                </div>
            </div>
        </>
    )
}

export default LiveTemplate
