import { useRecoilState } from 'recoil'
import { analyticsState } from '../../stores/campaign/analytics/analyticsState'
import { loadAnalytics } from './loadAnalytics'
import IAnalytics from '../../types/Analytics/Analytics'

interface Props {
    load: (analytics: IAnalytics) => any
    page: (url?: string) => void
    track: (event: string, data: { [key: string]: any }) => void
    identify: (uid: string, data: { [key: string]: any }) => void
}

export const useAnalytics = (id: string): Props => {
    const [analytics, setAnalytics] = useRecoilState(analyticsState(id))

    return {
        load: (analytics): any => {
            const analytic = loadAnalytics(analytics || {})
            setAnalytics(analytic)
            return analytic
        },
        page: (url: string = ''): void => {
            url === '' ? analytics.page() : analytics.page(url)
        },
        track: (event: string, data: { [key: string]: any }): void => {
            analytics.track(event, data)
        },
        identify: (uid: string, data: { [key: string]: any }): void => {
            analytics.identify(uid, data)
        },
    }
}
